import { configureStore } from '@reduxjs/toolkit'
import { reducer as firebaseReducer, } from 'react-redux-firebase'
import { firestoreReducer, } from 'redux-firestore'
import { navigationReducer, } from './features/navigation';

export default function createStore(initialState = {}) {
  const enhancers = []

  const store = configureStore({
    reducer: { 
      firebase: firebaseReducer,
      firestore: firestoreReducer,
      navigation: navigationReducer,
    },
    initialState: initialState,
    enhancers: enhancers,
  })
  
  return store;
};
