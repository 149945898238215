import firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";

import {
  useFirebaseEmulator,
} from "./data/season-constants";

const firebaseConfig = {
  apiKey: "AIzaSyAX_Acqz3oWaJ3AXdZvYl5Qx-YHxbbiAps",
  authDomain: "terrapin-challenge.firebaseapp.com",
  projectId: "terrapin-challenge",
  storageBucket: "terrapin-challenge.appspot.com",
  messagingSenderId: "753695051919",
  appId: "1:753695051919:web:bae55441805eb3a82da0fa",
  measurementId: "G-GQDX5ML376"
};
firebase.initializeApp(firebaseConfig);

export default firebase;

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

// eslint-disable-next-line no-restricted-globals
if (useFirebaseEmulator && window.location.hostname === 'localhost') {
  firestore.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099/', { disableWarnings: true });
  storage.useEmulator("localhost", 9199);
}

export { auth, firestore, storage }; 

export const performance = firebase.performance();

export let analytics;

if (process.env.NODE_ENV !== "test") {
  analytics = firebase.analytics();
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true
}

export const firestoreConstants = {
  populateUserIds: [{
    child: "userId",
    root: "users",
  }],
};
