import React, { Component } from "react";

import {
  Box,
  Hidden,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import MUIDataTable from "mui-datatables";

import EntrySelectHeader from "../EntrySelectHeader/EntrySelectHeader";

import userEntries from "../../services/user-entries";

const styles = (theme) => ({
});

const mobileColumns = [
  { label: 'Original Data', name: 'originalData', options: {
    display: false,
    filter: false,
    sort: false,
  }},
  { label: 'Name', name: 'ownerName', options: {
    sortThirdClickReset: true, 
  }},
  { label: 'Bracket Name', name: 'bracketName', options: {
    sortThirdClickReset: true, 
  }},
];


class EntrySelector extends Component {
  transformUserEntries = (userEntryData) => {
      return Object.entries(userEntryData)
          .map(([id, entry]) => {
        const {
          ownerName,
          bracketName,
          userId,
          firstFourPicks,
        } = entry;

        let gridData = {
          id,
          ownerName,
          bracketName,
          userId,
          firstFourPicks,
        };

        gridData = {
          originalData: {...gridData},
          ...gridData,
        };

        return gridData;
      });
    }

  render() {
    const {
      userEntries: userEntryData,
      user,
      openSnackbar,
      name,
    } = this.props;

    const tableData = this.transformUserEntries(userEntryData);
    const initialSelected = tableData.map((row, index) => {
      return {
        ...row,
        index: index,
      };
    }).filter((row) => row.userId === user.uid)
     .map((row) => row.index);

    return (
      <Box>
        <Hidden mdUp>
          <MUIDataTable
            title={"Which brackets are yours?"}
            columns={mobileColumns}
            data={tableData}
            options={{
              elevation: 0,
              pagination: false,
              download: false,
              print: false,
              filter: false,
              viewColumns: false,
              search: false,
              responsive: "standard",
              tableBodyMaxHeight: "100%",
              selectableRows: "multiple",
              selectableRowsHeader: false,
              selectableRowsOnClick: true,
              selectToolbarPlacement: "above",
              rowsSelected: initialSelected,
              textLabels: {
                selectedRows: {
                  text: "bracket(s) selected",
                },
              },
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <EntrySelectHeader
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  initialSelected={initialSelected}
                  name={name}
                  openSnackbar={openSnackbar}
                />
              ),
              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                if (allRowsSelected.length === 0 && initialSelected.length > 0) {
                  const uncheckedBrackets = initialSelected.map((index) => tableData[index]);
                  userEntries.clearBracketsUserId(uncheckedBrackets, openSnackbar);
                }
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                if (
                  selectedRows.data.length > 1 &&
                  selectedRows.data.filter(d => d.dataIndex === dataIndex).length === 0
                ) {
                  return false;
                }

                const {
                  userId = null,
                } = tableData[dataIndex];

                if (userId && userId !== user.uid) {
                  return false;
                }
               
                return true;
              },
              setTableProps: () => {
                return {
                  size: "small",
                };
              },
            }}
          />
        </Hidden>
        <Hidden smDown>
          <MUIDataTable
            title={"Which brackets are yours?"}
            columns={mobileColumns}
            data={tableData}
            options={{
              elevation: 0,
              pagination: false,
              download: false,
              print: false,
              filter: false,
              viewColumns: false,
              search: false,
              tableBodyMaxHeight: "100%",
              selectableRows: "none",
              responsive: "standard",
              selectableRows: "multiple",
              selectableRowsHeader: false,
              selectableRowsOnClick: true,
              selectToolbarPlacement: "above",
              rowsSelected: initialSelected,
              textLabels: {
                selectedRows: {
                  text: "bracket(s) selected",
                },
              },
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <EntrySelectHeader
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  initialSelected={initialSelected}
                  name={name}
                  openSnackbar={openSnackbar}
                />
              ),
              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                if (allRowsSelected.length === 0 && initialSelected.length > 0) {
                  const uncheckedBrackets = initialSelected.map((index) => tableData[index]);
                  userEntries.clearBracketsUserId(uncheckedBrackets, openSnackbar);
                }
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                if (
                  selectedRows.data.length > 1 &&
                  selectedRows.data.filter(d => d.dataIndex === dataIndex).length === 0
                ) {
                  return false;
                }

                const {
                  userId = null,
                } = tableData[dataIndex];

                if (userId && userId !== user.uid) {
                  return false;
                }
               
                return true;
              },
            }}
          />
        </Hidden>
      </Box>
    );
  }
}

export default withStyles(styles)(EntrySelector);
