// Question Types
export const STRING = "STRING";
export const NUMERIC = "NUMERIC";
export const RADIO = "RADIO";
export const SELECT_CONTESTANT = "SELECT_CONTESTANT";

// Answer Types
export const BINARY_STRING = "BINARY_STRING";
export const BINARY_NUMBER = "BINARY_NUMBER";
export const ONE_OF = "ONE_OF";
export const CLOSEST = "CLOSEST";
export const WITHIN_AMOUNT = "WITHIN_AMOUNT";
export const WITHIN_PERCENT = "WITHIN_PERCENT";
export const RADIO_SINGLE = "RADIO_SINGLE";
