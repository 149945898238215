import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, Link as RouterLink, } from "react-router-dom";

import { get, difference, } from "lodash";

import {
  firestoreConnect,
  isLoaded,
  populate,
} from 'react-redux-firebase'

import { auth, firestoreConstants, } from "../../firebase";

import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  ButtonGroup,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineDot,
  Alert,
} from "@material-ui/lab";

import Loader from "../Loader";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import authentication from "../../services/authentication";

import {
  myUserEntry,
} from "../../data/season-constants";

import routes, {
  SEASONS,
  FAQS,
} from "../../data/routes";

import FirstFourPicks from "../FirstFourPicks/FirstFourPicks";

import EntrySelector from "../EntrySelector/EntrySelector";

import userEntries from "../../services/user-entries";

import { ExpandMore } from "@material-ui/icons";

const styles = (theme) => ({
  timelineRoot: {
    "& .MuiTimeline-root": {
      padding: theme.spacing(0),
    }
  },
  ul: {
    margin: 0,
  },
});

function ordinalSuffixOf(i) {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return i + "st";
  }
  if (j == 2 && k != 12) {
      return i + "nd";
  }
  if (j == 3 && k != 13) {
      return i + "rd";
  }
  return i + "th";
}

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
      name: undefined,
      bracket1Name: "",
      bracket2Name: "",
      firstFourPicks: { },
    };

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleBracket1Change = this.handleBracket1Change.bind(this);
    this.handleBracket2Change = this.handleBracket2Change.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.saveBracket1Change = this.saveBracket1Change.bind(this);
    this.saveBracket2Change = this.saveBracket2Change.bind(this);
    this.saveNameChange = this.saveNameChange.bind(this);
  }

  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const {
        myUserEntryAll,
        seasons,
      } = this.props;

      const {
        name: stateName,
      } = this.state;

      const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll, seasons);
      const prevUserEntry = userEntries.getMyUserEntryFromAllOrDefault(prevProps.myUserEntryAll, seasons);

      const {
        firstFourPicks = {},
        name = "",
        bracket1Name = "",
        bracket2Name = "",
      } = myUserEntry;
    
      let newStateName = stateName;
      if (stateName === undefined && name !== "") {
        newStateName = name;
      }

      this.setState({
        firstFourPicks: {
          ...firstFourPicks,
        },
        name: newStateName,
        bracket1Name: bracket1Name,
        bracket2Name: bracket2Name,
      });
    }
  }

  handleAnswerChange = (gameId, answer) => {
    const updatedFirstFourAnswers = {
      ...this.state.firstFourPicks,
      [gameId]: answer,
    };

    this.setState({
      firstFourPicks: updatedFirstFourAnswers,
    });

    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    userEntries.updateFirstFourPicks(myUserEntryAll, updatedFirstFourAnswers, openSnackbar);
  };

  handleBracket1Change = (event) => {
    this.setState({
      bracket1Name: event.target.value,
    });
  };

  handleBracket2Change = (event) => {
    this.setState({
      bracket2Name: event.target.value,
    });
  };

  handleNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  saveBracket1Change = () => {
    const {
      bracket1Name
    } = this.state;

    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    userEntries.updateBracket1Name(myUserEntry, bracket1Name, openSnackbar);
  };

  saveBracket2Change = () => {
    const {
      bracket2Name
    } = this.state;

    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    userEntries.updateBracket2Name(myUserEntry, bracket2Name, openSnackbar);
  };

  saveNameChange = () => {
    const {
      name
    } = this.state;

    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    if (myUserEntryAll !== undefined && myUserEntryAll.length > 0) {
      userEntries.updateName(myUserEntryAll, name, openSnackbar);
    }
  };
  
  isLoaded = () => {
    const {
      seasons,
      userEntries,
      myUserEntryAll,
      myUserEntryById,
    } = this.props;

    if (this.props.user) {
      return isLoaded(
        seasons,
        userEntries,
        myUserEntryAll,
        myUserEntryById,
      );
    } else {
      return isLoaded(
        seasons,
      );
    }
  }

  render() {
    // Styling
    const { classes } = this.props;

    const {
      onSignUpClick,
      onSignInClick,
      onSettingsClick,
      openSnackbar,
    } = this.props;

    const {
      user,
      seasons,
      baseUrl,
      myUserEntryAll,
      userEntries,
    } = this.props;

    const {
      firstFourPicks,
      name,
      bracket1Name,
      bracket2Name,
    } = this.state;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const isRankingsLocked = false;
    const rankingsComplete = false;
    const seasonPredictionsComplete = false;
    const isUserComplete = false;
    const isSeasonPredictionsLocked = false;
    const isWeeklyPredictionsLocked = false;

    const season = seasons[seasonId];

    const {
      games,
      teams,
      consecutiveYears,
      isSeasonOver,
      isFirstFourPicksLocked,
      isEntryLocked,
      groupUrl,
    } = season;

    const firstFourGameIds = ["63", "64", "65", "66"]
    const firstFourGames = firstFourGameIds.map((gameId) => games[gameId]);
    const unlockedFirstFourGames = firstFourGames.filter((game) => !game.isLocked);
    const unlockedFirstFourGameIds = unlockedFirstFourGames.map((game) => game.id.toString()).sort();
    const firstFourPickIds = Object.keys(firstFourPicks).sort();
    const hasEntry = myUserEntryAll !== undefined && myUserEntryAll.length > 0;

    const firstFourPicksComplete = difference(unlockedFirstFourGameIds, firstFourPickIds).length === 0;
    const infoComplete = (name !== "" && hasEntry);

    return (
      <>
        <Hidden mdUp>
          <Box pt={2} px={1}>
            {isSeasonOver && (
              <Typography variant="h4" gutterBottom>
                Thanks for joining the {ordinalSuffixOf(consecutiveYears)} annual Terrapin Challenge!
              </Typography>
            )}
            {!isSeasonOver && (
              <>
                <Typography variant="h4" >
                  Join us for the {ordinalSuffixOf(consecutiveYears)} annual Terrapin Challenge!
                </Typography>
                <Box p={2}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                    >
                      <Typography variant="h6">Detailed Rules:</Typography>
                    </AccordionSummary> 
                    <AccordionDetails>
                      <Box
                        display="flex"
                        flexDirection="column"
                      >
                        <ul className={classes.ul}>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Typical to normal bracket scoring, points for correct picks are doubled each round (i.e. 10 points per pick in the first round, 20 per pick in the second round, etc.)
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              <b>Important!</b> Points for a correct pick are doubled when the difference in seeds is <b>3</b> or more!
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              If you get your bracket in before the start of all or some of the First Four games, you can make selections for the remaining games (see the last step below)! These are worth <b>5</b> points.
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              You may submit a maximum of <b>2</b> brackets.
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              The required donation is <b>$30</b> per bracket and can be sent via Venmo to <b>@Nathan-Case6</b>. Reach out to Nathan to arrange an alternate donation method.
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Pay-out: (2023 results -- 52 entrants @ $1,560 total donations)
                            </Typography>
                            <Typography variant="body1">
                              - <b>1st</b> overall: <b>60%</b> (Brian Shade - $936)
                            </Typography>
                            <Typography variant="body1">
                              - <b>2nd</b> overall: <b>25%</b> (Stone Chen - $390)
                            </Typography>
                            <Typography variant="body1">
                              - <b>3rd</b> overall: <b>10%</b> (Allie Cell - $156)
                            </Typography>
                            <Typography variant="body1">
                              - Most points after the 1st round: <b>5%</b> (Logan Seitz - $78)
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Want to know more? Visit the <Link
                                component={RouterLink}
                                to={`${baseUrl}${routes[FAQS].path}`}
                              >
                                FAQs
                              </Link>!
                            </Typography>
                          </li>
                        </ul>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Typography variant="h5" gutterBottom>
                  Here's how to play along:
                </Typography>
                <Timeline classes={classes.timelineRoot} align="left">
                  <TimelineItem>
                    <TimelineOppositeContent style={{ flex: 0 }} />
                    <TimelineSeparator>
                      <TimelineDot 
                        variant="outlined"
                        color="primary"
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box
                        mt={-1}
                        mb={5}
                      >
                        <Typography variant="h5" gutterBottom>
                          Fill out your bracket in the <Link
                            target="_blank"
                            href={groupUrl}
                            rel="noreferrer"
                          >Terrapin Challenge ESPN Group</Link>
                        </Typography>
                        <Typography variant="body1" paragraph>
                          The password for the Terrapin Challenge ESPN Group is <b>Testudo</b>.
                        </Typography>
                        <Typography variant="body1" paragraph>
                          Picking an upset correctly will score double points!
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                  {!user && (
                    <TimelineItem>
                      <TimelineOppositeContent style={{ flex: 0 }} />
                      <TimelineSeparator>
                        <TimelineDot 
                          variant="outlined"
                          color="primary"
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box
                          mt={-1}
                          mb={5}
                        >
                          <Typography variant="h5" gutterBottom>
                            Newcomers: Create an account
                          </Typography>
                          <Typography variant="body1" paragraph>
                             Repeat offenders: sign in
                          </Typography>
                          <ButtonGroup
                            color="primary"
                            variant="contained"
                          >
                            <Button onClick={onSignUpClick}>Sign up</Button>
                            <Button onClick={onSignInClick}>Sign in</Button>
                          </ButtonGroup>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  )}
                  {user && (
                    <TimelineItem>
                      <TimelineOppositeContent style={{ flex: 0 }} />
                      <TimelineSeparator>
                        <TimelineDot 
                          variant="outlined"
                          color="primary"
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box
                          mt={-1}
                          mb={5}
                        >
                          <Typography variant="h5" gutterBottom>
                            Claim your bracket(s)
                          </Typography>
                          {user && !isFirstFourPicksLocked && (
                            <Box pb={2}>
                              <Box pb={2}>
                                <Alert
                                  severity={infoComplete ? "success" : "warning"}
                                >
                                  {infoComplete 
                                    ? <strong>Bracket info complete!</strong>
                                    : <strong>You still need to input your bracket info!</strong>
                                  }
                                </Alert>
                              </Box>
                              <TextField
                                disabled={isEntryLocked}
                                fullWidth
                                type={"text"}
                                value={name || ""}
                                variant="outlined"
                                size="small"
                                label={"What's your name?"}
                                onChange={(event) => this.handleNameChange(event)}
                                onBlur={() => this.saveNameChange()}
                              />
                            </Box>
                          )}
                          {user && !isFirstFourPicksLocked && (
                            <>
                              <Box pb={1}>
                                <EntrySelector
                                  userEntries={userEntries}
                                  user={user}
                                  openSnackbar={openSnackbar}
                                  name={name} 
                                />
                              </Box>
                              <Typography variant="body2">
                                <i>Don't see your bracket? It may take 1-2 minutes to load once you've added yourself to the ESPN group</i>
                              </Typography>
                            </>
                          )}
                          {/* {user && !isFirstFourPicksLocked && (
                            <Box pb={2}>
                              <TextField
                                disabled={isEntryLocked}
                                fullWidth
                                type={"text"}
                                value={bracket1Name || ""}
                                variant="outlined"
                                size="small"
                                label={"Bracket Name:"}
                                onChange={(event) => this.handleBracket1Change(event)}
                                onBlur={() => this.saveBracket1Change()}
                              />
                            </Box>
                          )}
                          {user && !isFirstFourPicksLocked && (bracket1Name !== "") && (
                            <>
                              <TextField
                                disabled={isEntryLocked}
                                fullWidth
                                type={"text"}
                                value={bracket2Name || ""}
                                variant="outlined"
                                size="small"
                                label={"(optional) Second Bracket:"}
                                onChange={(event) => this.handleBracket2Change(event)}
                                onBlur={() => this.saveBracket2Change()}
                              />
                            </>
                          )} */}
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  )}
                  <TimelineItem>
                    <TimelineOppositeContent style={{ flex: 0 }} />
                    <TimelineSeparator>
                      <TimelineDot 
                        variant="outlined"
                        color="primary"
                      />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box
                        mt={-1}
                        mb={5}
                      >
                        <Typography variant="h5" gutterBottom>
                          Make your picks for the First Four games!
                        </Typography>
                        <Typography variant="body1" paragraph>
                          Each game is worth 5 points!
                        </Typography>
                        {!user && !isFirstFourPicksLocked && (
                          <FirstFourPicks
                            teams={teams}
                            games={games}
                            firstFourPicks={firstFourPicks}
                            disabled
                          />
                        )}
                        {user && !isFirstFourPicksLocked && (
                          <>
                            {infoComplete && (
                              <Alert
                                severity={firstFourPicksComplete ? "success" : "warning"}
                              >
                                {firstFourPicksComplete 
                                  ? <strong>You're finished with your First Four picks!</strong>
                                  : <strong>You still need to finish your First Four picks!</strong>
                                }
                              </Alert>
                            )}
                            <FirstFourPicks
                              teams={teams}
                              games={games}
                              handleAnswerChange={this.handleAnswerChange}
                              firstFourPicks={firstFourPicks}
                              disabled={!hasEntry}
                            />
                          </>
                        )}
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </>
            )}
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box>
              <Box pt={3} px={4}>
                {isSeasonOver && (
                  <Typography variant="h4" gutterBottom>
                    Thanks for joining the {ordinalSuffixOf(consecutiveYears)} annual Terrapin Challenge!
                  </Typography>
                )}
                {!isSeasonOver && (
                  <>
                    <Typography variant="h3" >
                      Join us for the {ordinalSuffixOf(consecutiveYears)} annual Terrapin Challenge!
                    </Typography>
                    <Box p={2}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography variant="h6">Detailed Rules:</Typography>
                        </AccordionSummary> 
                        <AccordionDetails>
                          <Box
                            display="flex"
                            flexDirection="column"
                          >
                            <ul className={classes.ul}>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  Typical to normal bracket scoring, points for correct picks are doubled each round (i.e. 10 points per pick in the first round, 20 per pick in the second round, etc.)
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  <b>Important!</b> Points for a correct pick are doubled when the difference in seeds is <b>3</b> or more!
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  If you get your bracket in before the start of all or some of the First Four games, you can make selections for the remaining games (see the last step below)! These are worth <b>5</b> points.
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  You may submit a maximum of <b>2</b> brackets.
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  The required donation is <b>$30</b> per bracket and can be sent via Venmo to <b>@Nathan-Case6</b>. Reach out to Nathan to arrange an alternate donation method.
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  Pay-out: (2023 results -- 52 entrants @ $1,560 total donations)
                                </Typography>
                                <Typography variant="body1">
                                  - <b>1st</b> overall: <b>60%</b> (Brian Shade - $936)
                                </Typography>
                                <Typography variant="body1">
                                  - <b>2nd</b> overall: <b>25%</b> (Stone Chen - $390)
                                </Typography>
                                <Typography variant="body1">
                                  - <b>3rd</b> overall: <b>10%</b> (Allie Cell - $156)
                                </Typography>
                                <Typography variant="body1">
                                  - Most points after the 1st round: <b>5%</b> (Logan Seitz - $78)
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body1" gutterBottom>
                                  Want to know more? Visit the <Link
                                    component={RouterLink}
                                    to={`${baseUrl}${routes[FAQS].path}`}
                                  >
                                    FAQs
                                  </Link>!
                                </Typography>
                              </li>
                            </ul>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      Here's how to play along:
                    </Typography>
                    <Timeline align="left">
                      <TimelineItem>
                        <TimelineOppositeContent style={{ flex: 0 }} />
                        <TimelineSeparator>
                          <TimelineDot 
                            variant="outlined"
                            color="primary"
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box
                            mt={-1}
                            mb={5}
                          >
                            <Typography variant="h5" gutterBottom>
                              Fill out your bracket in the <Link
                                target="_blank"
                                href={groupUrl}
                                rel="noreferrer"
                              >Terrapin Challenge ESPN Group</Link>
                            </Typography>
                            <Typography variant="body1" paragraph>
                              The password for the Terrapin Challenge ESPN Group is <b>Testudo</b>.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              Picking an upset correctly will score double points!
                            </Typography>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                      {!user && (
                        <TimelineItem>
                          <TimelineOppositeContent style={{ flex: 0 }} />
                          <TimelineSeparator>
                            <TimelineDot 
                              variant="outlined"
                              color="primary"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Box
                              mt={-1}
                              mb={5}
                            >
                              <Typography variant="h5" gutterBottom>
                                Newcomers: Create an account
                              </Typography>
                              <Typography variant="body1" paragraph>
                                Repeat offenders: sign in
                              </Typography>
                              <ButtonGroup
                                color="primary"
                                variant="contained"
                              >
                                <Button onClick={onSignUpClick}>Sign up</Button>
                                <Button onClick={onSignInClick}>Sign in</Button>
                              </ButtonGroup>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      )}
                      {user && (
                        <TimelineItem>
                          <TimelineOppositeContent style={{ flex: 0 }} />
                          <TimelineSeparator>
                            <TimelineDot 
                              variant="outlined"
                              color="primary"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Box
                              mt={-1}
                              mb={5}
                            >
                              <Typography variant="h5" gutterBottom>
                                Claim your bracket(s)
                              </Typography>
                              {user && !isFirstFourPicksLocked && (
                                <Box pb={2}>
                                  <Box pb={2}>
                                    <Alert
                                      severity={infoComplete ? "success" : "warning"}
                                    >
                                      {infoComplete 
                                        ? <strong>Bracket info complete!</strong>
                                        : <strong>You still need to input your bracket info!</strong>
                                      }
                                    </Alert>
                                  </Box>
                                  <TextField
                                    disabled={isEntryLocked}
                                    fullWidth
                                    type={"text"}
                                    value={name || ""}
                                    variant="outlined"
                                    size="small"
                                    label={"What's your name?"}
                                    onChange={(event) => this.handleNameChange(event)}
                                    onBlur={() => this.saveNameChange()}
                                  />
                                </Box>
                              )}
                              {user && !isFirstFourPicksLocked && (
                                <>
                                  <Box pb={1}>
                                    <EntrySelector
                                      userEntries={userEntries}
                                      user={user}
                                      openSnackbar={openSnackbar}
                                      name={name} 
                                    />
                                  </Box>
                                  <Typography variant="body2">
                                    <i>Don't see your bracket? It may take 1-2 minutes to load once you've added yourself to the ESPN group.</i>
                                  </Typography>
                                </>
                              )}
                              {/* {user && !isFirstFourPicksLocked && (
                                <Box pb={2}>
                                  <TextField
                                    disabled={isEntryLocked}
                                    fullWidth
                                    type={"text"}
                                    value={bracket1Name || ""}
                                    variant="outlined"
                                    size="small"
                                    label={"Bracket Name:"}
                                    onChange={(event) => this.handleBracket1Change(event)}
                                    onBlur={() => this.saveBracket1Change()}
                                  />
                                </Box>
                              )}
                              {user && !isFirstFourPicksLocked && (bracket1Name !== "") && (!isEntryLocked && bracket2Name !== "") && (
                                <>
                                  <TextField
                                    disabled={isEntryLocked}
                                    fullWidth
                                    type={"text"}
                                    value={bracket2Name || ""}
                                    variant="outlined"
                                    size="small"
                                    label={"(optional) Second Bracket Name:"}
                                    onChange={(event) => this.handleBracket2Change(event)}
                                    onBlur={() => this.saveBracket2Change()}
                                  />
                                </>
                              )}
                              {user && isEntryLocked && bracket1Name !== "" && bracket2Name !== "" && (
                                <>
                                  <TextField
                                    disabled={isEntryLocked}
                                    fullWidth
                                    type={"text"}
                                    value={bracket2Name || ""}
                                    variant="outlined"
                                    size="small"
                                    label={"(optional) Second Bracket Name:"}
                                    onChange={(event) => this.handleBracket2Change(event)}
                                    onBlur={() => this.saveBracket2Change()}
                                  />
                                </>
                              )} */}
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      )}
                      <TimelineItem>
                        <TimelineOppositeContent style={{ flex: 0 }} />
                        <TimelineSeparator>
                          <TimelineDot 
                            variant="outlined"
                            color="primary"
                          />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box
                            mt={-1}
                            mb={5}
                          >
                            <Typography variant="h5" gutterBottom>
                              Make your picks for the First Four games!
                            </Typography>
                            <Typography variant="body1" paragraph>
                              Each game is worth 5 points!
                            </Typography>
                            {!user && !isFirstFourPicksLocked && (
                              <FirstFourPicks
                                teams={teams}
                                games={games}
                                firstFourPicks={firstFourPicks}
                                disabled
                              />
                            )}
                            {user && !isFirstFourPicksLocked && (
                              <>
                                {infoComplete && (
                                  <Alert
                                    severity={firstFourPicksComplete ? "success" : "warning"}
                                  >
                                    {firstFourPicksComplete 
                                      ? <strong>You're finished with your First Four picks!</strong>
                                      : <strong>You still need to finish your First Four picks!</strong>
                                    }
                                  </Alert>
                                )}
                                <FirstFourPicks
                                  teams={teams}
                                  games={games}
                                  handleAnswerChange={this.handleAnswerChange}
                                  firstFourPicks={firstFourPicks}
                                  disabled={!hasEntry}
                                />
                              </>
                            )}
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </>
                )}
              </Box>
          </Box>
        </Hidden>
      </>

    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    seasons: state.firestore.data.seasons,
    userEntries: state.firestore.data[`${seasonId}#userEntries`],
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    ...(props.user ? [
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
      }],
      storeAs: `${seasonId}#userEntries`,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        where: ["userId", "==", props.user.uid],
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#${myUserEntry}`,
    }] : [])
  ];
}

export default compose(
  withStyles(styles),
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(HomePage);

