import React, { Component } from "react";

import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";

import { makeStyles, } from "@material-ui/core/styles";

import gameService from "../../services/game-service";

const useStyles = makeStyles((theme) => ({
  teamSeed: {
    marginLeft: theme.spacing(1),
    marginTop: "1px",
    fontSize: "11px",
    width: "11px",
    color: "#999"
  },
  teamName: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
  },
}));

const TeamRow = (props) => {
  const classes = useStyles();
  const {
    team,
    score,
    isTeam1,
    handleScoreChange,
  } = props;

  const {
    name,
    abbreviation,
    seed,
    icon_path,
  } = team;

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      {icon_path && (
        <img
          src={require(`../../assets/icons/${icon_path}`).default}
          height={16}
          width={16}
        />
      )}
      <Typography
        className={classes.teamSeed}
        align={"right"}
      >
        {seed}
      </Typography>
      <Typography
        className={classes.teamName}
      >
        {name}
      </Typography>
      <TextField
        value={score}
        type={"number"}
        variant="filled"
        size="small"
        onChange={(event) => handleScoreChange(event, isTeam1)}
      />
    </Box>
  );
};

class AdminGameEntry extends Component {
  constructor(props) {
    super(props);

    const {
      team1Score,
      team2Score,
    } = props.game;

    this.state = {
      team1Score: team1Score,
      team2Score: team2Score,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, isTeam1) {
    let score = event.target.value;

    if (score !== "") {
      score = parseFloat(score);
    } 

    if (isTeam1) {
      this.setState({
        team1Score: score,
      });
    } else {
      this.setState({
        team2Score: score,
      });
    }
  }

  onSaveClick = async () => {
    const {
      game,
      openSnackbar,
    } = this.props;

    const {
      team1Score,
      team2Score,
    } = this.state;

    const updatedGame = {
      ...game,
      team1Score: team1Score,
      team2Score: team2Score,
    };

    await gameService.updateGame(updatedGame, openSnackbar);
  }

  render() {
    const {
      game,
      team1,
      team2,
    } = this.props;

    const {
      team1Score,
      team2Score,
    } = this.state;

    return (
      <Box m={2}>
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item xs={11}>
                <TeamRow
                  team={team1}
                  score={team1Score}
                  isTeam1
                  handleScoreChange={this.handleChange}
                />
                <TeamRow
                  team={team2}
                  score={team2Score}
                  handleScoreChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={1}>
                <Button onClick={this.onSaveClick}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
  }
}

export default(AdminGameEntry);
