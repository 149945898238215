
import React, { Component, } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { get } from "lodash";

import { firestoreConnect } from 'react-redux-firebase'

import { withRouter, } from "react-router-dom";

import { isLoaded, } from "react-redux-firebase";

import { Link as RouterLink } from "react-router-dom";

import {
  Hidden,
  Box,
  Grid,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import BracketRegion from "../BracketRegion";

import EmptyState from "../EmptyState";

import Loader from "../Loader";

import FinalFourPanel from "../FinalFourPanel";

import FirstFourPicks from "../FirstFourPicks/FirstFourPicks";

import {
  selectCurrentSeason,
} from '../../features/navigation/navigationSlice';

import { ReactComponent as NoDataIllustration } from "../../assets/illustrations/no-data.svg";

const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
  mobileContainer: {
    maxWidth: "100%",
    overflowX: "auto",
  },
});

class UserEntryPage extends Component {
  isLoaded = () => {
    const {
      seasons,
      userEntry,
    } = this.props;

    return isLoaded(seasons, userEntry);
  }

  render() {
    // Styling
    const { classes } = this.props;

    const {
      currentSeason,
      seasons,
      userEntry,
    } = this.props;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const season = get(seasons, seasonId);

    if (!userEntry) {
      return (
        <EmptyState
          type="card"
          image={<NoDataIllustration />}
          title="No Entry"
          description="No Entry"
        />
      )
    }

    const {
      firstFourPicks = {},
    } = userEntry;

    const {
      teams,
      games,
      regions,
      hasDetailedFirstFour,
    } = season;

    const {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
    } = regions;

    return (
      <>
        <Hidden smDown>
          <Box pt={1}>
            {hasDetailedFirstFour && (
              <FirstFourPicks
                teams={teams}
                games={games}
                firstFourPicks={firstFourPicks}
                inMainBracket
              />
            )}
            <Grid container>
              <Grid item xs={6}>
                <BracketRegion
                  season={season}
                  userEntry={userEntry}
                  region={topLeft}
                  alignment="left"
                  verticalAlignment="top"
                />
              </Grid>
              <Grid item xs={6}>
                <BracketRegion
                  season={season}
                  userEntry={userEntry}
                  region={topRight}
                  alignment="right"
                  verticalAlignment="top"
                />
              </Grid>
              <Grid
                container
                item
                justify="center"
                xs={12}
              >
                <FinalFourPanel
                  season={season}
                  userEntry={userEntry}
                />
              </Grid>
              <Grid item xs={6}>
                <BracketRegion
                  season={season}
                  userEntry={userEntry}
                  region={bottomLeft}
                  alignment="left"
                  verticalAlignment="bottom"
                />
              </Grid>
              <Grid item xs={6}>
                <BracketRegion
                  season={season}
                  userEntry={userEntry}
                  region={bottomRight}
                  alignment="right"
                  verticalAlignment="bottom"
                />
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.mobileContainer}>
            <Box
              display="flex"
            >
              <Box>
                <Grid item xs={12}>
                    <BracketRegion
                    season={season}
                    userEntry={userEntry}
                    region={topLeft}
                    alignment="left"
                    verticalAlignment="top"
                  />
                </Grid>
                <Grid item xs={12}>
                  <BracketRegion
                    season={season}
                    userEntry={userEntry}
                    region={bottomLeft}
                    alignment="left"
                    verticalAlignment="bottom"
                  />
                </Grid>
                <Grid item xs={12}>
                  <BracketRegion
                    season={season}
                    userEntry={userEntry}
                    region={topRight}
                    alignment="left"
                    verticalAlignment="top"
                  />
                </Grid>
                <Grid item xs={12}>
                  <BracketRegion
                    season={season}
                    userEntry={userEntry}
                    region={bottomRight}
                    alignment="left"
                    verticalAlignment="bottom"
                  />
                </Grid>
              </Box>
              <FinalFourPanel
                season={season}
                userEntry={userEntry}
              />
            </Box>
            {hasDetailedFirstFour && (
              <FirstFourPicks
                teams={teams}
                games={games}
                firstFourPicks={firstFourPicks}
                inMainBracket
              />
            )}
          </Box>
        </Hidden>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
    userEntryId,
  } = params;

  const currentSeason = selectCurrentSeason(state);

  return {
    currentSeason: currentSeason,
    seasons: state.firestore.data.seasons,
    userEntry: (state.firestore.data[`${seasonId}#${userEntryId}`]),
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
    userEntryId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        doc: userEntryId,
      }],
      storeAs: `${seasonId}#${userEntryId}`,
    },
  ];
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps),
  firestoreConnect(registerFirestoreListeners),
)(UserEntryPage);
