import React, { Component } from "react";

import {
  Box,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { ExpandMore } from "@material-ui/icons";

const styles = (theme) => ({
});

class FAQPage extends Component {
  render() {

    return (
      <Box>
        <Hidden mdUp>
          <Box pt={2} px={1}>
            <Typography variant="h4" gutterBottom>
              Frequently Asked Questions:
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">How are brackets scored in the Terrapin Challenge?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    Typical to normal bracket scoring, points for correct picks are doubled each round. First round wins are worth 10 points, second round wins are 20 points, Sweet Sixteen wins are 40 points, Elite Eight wins are 80 points, Final Four wins are 160 points and picking the Championship game is worth 320 points.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    However, there are two main variations from typical scoring: 1) points for correct picks are doubled when the difference in seeds is three or more and 2) you will get 5 points (half of the score of first round games) for each of the First Four games that you select correctly.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">What’s the deal with these “First Four” games?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    As you may know, the First Round games start on Thursday each year. However, there are four games that take place over Tuesday and Wednesday that are commonly referred to as the “First Four” or “Play-In Games”.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    At the time of completing your bracket, you’ll have the option to select picks for any of the First Four games that have not started. These picks are only optional and you can still participate even if you don’t select any First Four picks. As they are only worth 5 points, they have very little bearing on your final score. I personally often procrastinate filling out my bracket until the last day and miss making the First Four picks, so truly they are not a big deal.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">What if I pick a team that is playing in the “First Four” games to make it to the second round (or farther) and they lose their play-in game?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    In the event that your First Four pick loses their play-in game, but you had them going farther in your bracket (second round or beyond), we will honor your pick as the team that beat your original First Four selection. In other words, even if you are incorrect in the First Four / Play-in game pick, you can still get first round points if the team that actually won in the First Four match-up makes it to the second round (or beyond).
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <b>Example:</b> Team A is playing Team B in one of the First Four games. You pick Team A to win the play-in game and win their first round match-up (and lose in the second round match-up). You are wrong about the first game and Team B wins instead; however, Team B wins their first round game and makes it to the second round. You still get points because we will honor Team B as your selection for round 1 games and beyond.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">For the point doubling for picking upsets, what if I pick an upset to occur, but the match-up does not end up being an upset opportunity?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    <b>Example:</b> I projected a 3 seed versus 7 seed match-up in the Sweet Sixteen with the 7 seed winning, but in reality the game is a 6 seed versus 7 seed and the 7 seed wins.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Scoring is based on what actually occurred in the bracket, not what you projected to happen. In the example above, this means that you would NOT get double points because the actual match-up that occurred was a 6-seed versus a 7-seed (not an upset opportunity match-up).
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Effectively, you are rewarded for upsets where you correctly choose the lower seed team AND the lower seed team actually played in an upset opportunity. However, this can help or hurt you. The above example is one that hurts you. You projected an upset that did not come to fruition, even though your team still won.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    If the example was reversed (you projected a 6-seed versus 7-seed match-up with 7-seed winning, but in reality the match-up was a 3-seed versus 7-seed and the 7-seed won), this would help you because you would get points for an upset even though you did not project the match-up to be an upset opportunity.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">How are ties resolved? What about a tie for final total points and a tie for most points after the first round?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    For final total points, the first tiebreaker is the most games picked correctly (wins) across all the rounds. The second tie breaker will be the closest total predicted score to the actual total score of the final game, meaning the two team’s scores added together.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    For most points after the first round, the number of wins after the first round will be the first tie-breaker. The second tie-breaker is the number of points predicted in the final game. So, we may not know who won the first round prize until after the final game.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box pt={3} px={4}>
            <Typography variant="h3" gutterBottom>
              Frequently Asked Questions:
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">How are brackets scored in the Terrapin Challenge?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    Typical to normal bracket scoring, points for correct picks are doubled each round. First round wins are worth 10 points, second round wins are 20 points, Sweet Sixteen wins are 40 points, Elite Eight wins are 80 points, Final Four wins are 160 points and picking the Championship game is worth 320 points.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    However, there are two main variations from typical scoring: 1) points for correct picks are doubled when the difference in seeds is three or more and 2) you will get 5 points (half of the score of first round games) for each of the First Four games that you select correctly.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">What’s the deal with these “First Four” games?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    As you may know, the First Round games start on Thursday each year. However, there are four games that take place over Tuesday and Wednesday that are commonly referred to as the “First Four” or “Play-In Games”.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    At the time of completing your bracket, you’ll have the option to select picks for any of the First Four games that have not started. These picks are only optional and you can still participate even if you don’t select any First Four picks. As they are only worth 5 points, they have very little bearing on your final score. I personally often procrastinate filling out my bracket until the last day and miss making the First Four picks, so truly they are not a big deal.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">What if I pick a team that is playing in the “First Four” games to make it to the second round (or farther) and they lose their play-in game?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    In the event that your First Four pick loses their play-in game, but you had them going farther in your bracket (second round or beyond), we will honor your pick as the team that beat your original First Four selection. In other words, even if you are incorrect in the First Four / Play-in game pick, you can still get first round points if the team that actually won in the First Four match-up makes it to the second round (or beyond).
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <b>Example:</b> Team A is playing Team B in one of the First Four games. You pick Team A to win the play-in game and win their first round match-up (and lose in the second round match-up). You are wrong about the first game and Team B wins instead; however, Team B wins their first round game and makes it to the second round. You still get points because we will honor Team B as your selection for round 1 games and beyond.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">For the point doubling for picking upsets, what if I pick an upset to occur, but the match-up does not end up being an upset opportunity?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    <b>Example:</b> I projected a 3 seed versus 7 seed match-up in the Sweet Sixteen with the 7 seed winning, but in reality the game is a 6 seed versus 7 seed and the 7 seed wins.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Scoring is based on what actually occurred in the bracket, not what you projected to happen. In the example above, this means that you would NOT get double points because the actual match-up that occurred was a 6-seed versus a 7-seed (not an upset opportunity match-up).
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Effectively, you are rewarded for upsets where you correctly choose the lower seed team AND the lower seed team actually played in an upset opportunity. However, this can help or hurt you. The above example is one that hurts you. You projected an upset that did not come to fruition, even though your team still won.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    If the example was reversed (you projected a 6-seed versus 7-seed match-up with 7-seed winning, but in reality the match-up was a 3-seed versus 7-seed and the 7-seed won), this would help you because you would get points for an upset even though you did not project the match-up to be an upset opportunity.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography variant="h6">How are ties resolved? What about a tie for final total points and a tie for most points after the first round?</Typography>
              </AccordionSummary> 
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="body1" paragraph>
                    For final total points, the first tiebreaker is the most games picked correctly (wins) across all the rounds. The second tie breaker will be the closest total predicted score to the actual total score of the final game, meaning the two team’s scores added together.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    For most points after the first round, the number of wins after the first round will be the first tie-breaker. The second tie-breaker is the number of points predicted in the final game. So, we may not know who won the first round prize until after the final game.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Hidden>
      </Box>
    );
  }
}

export default withStyles(styles)(FAQPage);
