import React, { Component } from "react";

import {
  Box,
  Paper,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";

import { makeStyles, withStyles, } from "@material-ui/core/styles";

import { get } from "lodash";

import BracketColumn from "../BracketColumn";

const styles = (theme) => ({
});

class BracketRegion extends Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const {
      classes,
      season,
      userEntry,
      region,
      alignment,
      verticalAlignment,
    } = this.props;

    const {
      games,
      teams,
      rounds,
    } = season;

    const {
      picksByGame,
    } = userEntry;

    const firstRoundGames = get(rounds, [64, region]);
    const secondRoundGames = get(rounds, [32, region]);
    const sweet16Games = get(rounds, [16, region]);
    const elite8Games = get(rounds, [8, region]);

    /*
    const leftSideRegions = ["west", "east"];
    const rightSideRegions = ["south", "midwest"];
    */
    const capitalizedRegion = this.capitalizeFirstLetter(region);

    if (alignment === "left") {
      return (
        <>
          <Hidden smDown>
            <Box m={1}>
              <Typography variant="h6">
                {capitalizedRegion}
              </Typography>
              <Grid
                container
                justify="space-between"
              >
                <Grid
                  xs={4}
                  container
                  item
                  direction="column" 
                >
                  <BracketColumn 
                    gamesInColumn={firstRoundGames} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    alignment="left"
                    verticalAlignment={verticalAlignment}
                    region={region}
                    round={64}
                    isFirstRound
                  />
                </Grid>
                <Grid
                  xs={4}
                  container
                  item
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={secondRoundGames} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    round={32}
                    alignment="left"
                    verticalAlignment={verticalAlignment}
                    region={region}
                  />
                </Grid>
                <Grid
                  xs={3}
                  container
                  item
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={sweet16Games} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    round={16}
                    alignment="left"
                    region={region}
                  />
                </Grid>
                <Grid
                  xs={1}
                  container
                  item
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={elite8Games} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    round={8}
                    alignment="left"
                    verticalAlignment={verticalAlignment}
                    region={region}
                  />
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              my={1}
              ml={1}
            >
              <Box
                display="flex"
              >
                <Grid
                  container
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={firstRoundGames} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    verticalAlignment={verticalAlignment}
                    alignment="left"
                    region={region}
                    round={64}
                    isFirstRound
                  />
                </Grid>
                <Grid
                  container
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={secondRoundGames} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    round={32}
                    alignment="left"
                    verticalAlignment={verticalAlignment}
                    region={region}
                  />
                </Grid>
                <Grid
                  container
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={sweet16Games} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    round={16}
                    alignment="left"
                    verticalAlignment={verticalAlignment}
                    region={region}
                  />
                </Grid>
                <Grid
                  container
                  direction="column" 
                  justify="space-around"
                >
                  <BracketColumn 
                    gamesInColumn={elite8Games} 
                    games={games}
                    teams={teams}
                    picksByGame={picksByGame}
                    round={8}
                    alignment="left"
                    verticalAlignment={verticalAlignment}
                    region={region}
                  />
                </Grid>
              </Box>
            </Box>
          </Hidden>
        </>
      );
    } else if (alignment === "right") {
      return (
        <Box m={1}>
          <Typography variant="h6" align="right">
            {capitalizedRegion}
          </Typography>
          <Grid
            container
            justify="space-between"
          >
            <Grid
              xs={1}
              container
              item
              direction="column" 
              justify="space-around"
            >
              <BracketColumn 
                gamesInColumn={elite8Games} 
                games={games}
                teams={teams}
                picksByGame={picksByGame}
                round={8}
                alignment="right"
                verticalAlignment={verticalAlignment}
                region={region}
              />
            </Grid>
            <Grid
              xs={3}
              container
              item
              direction="column" 
              justify="space-around"
            >
              <BracketColumn 
                gamesInColumn={sweet16Games} 
                games={games}
                teams={teams}
                picksByGame={picksByGame}
                round={16}
                alignment="right"
                verticalAlignment={verticalAlignment}
                region={region}
              />
            </Grid>
            <Grid
              xs={4}
              container
              item
              direction="column" 
              justify="space-around"
            >
              <BracketColumn 
                gamesInColumn={secondRoundGames} 
                games={games}
                teams={teams}
                picksByGame={picksByGame}
                round={32}
                alignment="right"
                verticalAlignment={verticalAlignment}
                region={region}
              />
            </Grid>
            <Grid
              xs={4}
              container
              item
              direction="column" 
            >
              <BracketColumn 
                gamesInColumn={firstRoundGames} 
                games={games}
                teams={teams}
                picksByGame={picksByGame}
                isFirstRound
                round={64}
                alignment="right"
                verticalAlignment={verticalAlignment}
                region={region}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }
  }
}

export default withStyles(styles)(BracketRegion);
