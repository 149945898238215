import React, { Component } from "react";

import {
  Box,
  Paper,
  Typography,
  Grid,
} from "@material-ui/core";

import { makeStyles, withStyles, } from "@material-ui/core/styles";

import appearance from "../../services/appearance";

import { Hidden } from "@material-ui/core";

const styles = (theme) => ({
});

const loserFontColor = "#999";

const useStyles = makeStyles((theme) => ({
  teamSeed: {
    marginLeft: theme.spacing(1),
    marginTop: "1px",
    fontSize: "11px",
    width: "11px",
    color: loserFontColor,
  },
  teamName: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
  },
  teamNameWon: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
  },
  teamNameCorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "green",
  },
  teamNameIncorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "red",
  },
  teamNameLoser: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    color: loserFontColor,
  },
  winnerScore: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  loserScore: {
    color: appearance.colors.gray.import[600],
    fontSize: "12px",
  },
  pickRow: {
    backgroundColor: appearance.colors.gray.import[100],
  },
  pickRowCorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid green",
  },
  pickRowIncorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid red",
  },
}));

const getPickRowClassName = (classes, isPickedToWin, isWinner) => {
  if (!isWinner && isPickedToWin) {
    return classes.pickRowIncorrect;
  } else if (isWinner && isPickedToWin) {
    return classes.pickRowCorrect;
  } else {
    return classes.pickRow;
  }
}

const PickRow = (props) => {
  const classes = useStyles();
  const {
    team,
    isWinner,
    isPickedToWin,
    isComplete,
    isInGame,
  } = props;

  const {
    name,
    abbreviation,
    isEliminated = false,
  } = team;

  if (!isComplete) {
    return (
      <Box
        display="flex"
        alignItems="center"
        minHeight="24px"
        className={classes.pickRow}
      >
        {!isEliminated && (
          <Typography
            className={classes.teamNameLoser}
          >
            {name}
          </Typography>
        )}
        {isEliminated && isPickedToWin && (
          <Typography
            className={classes.teamNameIncorrect}
          >
            <s>{name}</s>
          </Typography>
        )}
        {isEliminated && !isPickedToWin && (
          <Typography
            className={classes.teamNameLoser}
          >
            <s>{name}</s>
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      minHeight="24px"
      className={getPickRowClassName(classes, isPickedToWin, isWinner)}
    >
      {isWinner && isPickedToWin && (
        <Typography
          className={classes.teamNameCorrect}
        >
          {name}
        </Typography>
      )}
      {isWinner && !isPickedToWin && (
        <Typography
          className={classes.teamNameLoser}
        >
          {name}
        </Typography>
      )}
      {!isWinner && isPickedToWin && isInGame && (
        <Typography
          className={classes.teamNameIncorrect}
        >
          {name}
        </Typography>
      )}
      {!isWinner && isPickedToWin && !isInGame && (
        <Typography
          className={classes.teamNameIncorrect}
        >
          <s>{name}</s>
        </Typography>
      )}
      {!isWinner && !isPickedToWin && isInGame && (
        <Typography
          className={classes.teamNameLoser}
        >
          {name}
        </Typography>
      )}
      {!isWinner && !isPickedToWin && !isInGame && (
        <Typography
          className={classes.teamNameLoser}
        >
          <s>{name}</s>
        </Typography>
      )}
    </Box>
  );
};

export default PickRow;
