import { SEASON_PREDICTIONS } from "./routes";

export const useFirebaseEmulator = false;

export const isRankingsLocked = true;
export const isSeasonPredictionsLocked = true;

export const isWeeklyPredictionsLocked = false;

export const currentSeason = "2024";
export const defaultSeason = "2024";
export const currentWeek = 9;
export const currentRoseCeremony = 9;

export const myUserEntry = "myUserEntry";

export const rankings = "RANKINGS";
export const seasonPredictions = "SEASON_PREDICTIONS";
export const setupSteps = [rankings, SEASON_PREDICTIONS];