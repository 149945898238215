import React, { Component } from "react";

import {
  Box,
  Button,
} from "@material-ui/core";

import SaveIcon from '@material-ui/icons/Save';

import { withStyles } from "@material-ui/core/styles";

import userEntries from "../../services/user-entries";

const styles = (theme) => ({
});

class EntrySelectHeader extends Component {
  constructor(props) {
    super(props);

    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleSaveClick = () => {
    const {
      selectedRows, 
      displayData,
      initialSelected,
      name,
      openSnackbar,
    } = this.props;
    const newCheckedRowIndicies = selectedRows.data.map((element) => element.index);
    const checkedBrackets = newCheckedRowIndicies.map((index) => displayData[index]);
    const uncheckedRowIndices = initialSelected.filter((index) => !newCheckedRowIndicies.includes(index));
    const uncheckedBrackets = uncheckedRowIndices.map((index) => displayData[index]);
    userEntries.updateClaimedBrackets(checkedBrackets, uncheckedBrackets, name, openSnackbar);
  };
  
  render() {
    const {
      selectedRows, 
      initialSelected,
    } = this.props;
    

    const savedBracketNum = initialSelected.length;
    const checkedBracketNum = selectedRows.data.length;
    const allChangesSaved = savedBracketNum === checkedBracketNum;

    let saveButtonText =  checkedBracketNum > 1 ? "Claim my brackets" : "Claim my bracket";
    if (checkedBracketNum < savedBracketNum) {
      saveButtonText = "Update claimed brackets";
    }

    return (
      <Box pr={3.25}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon/>}
          onClick={this.handleSaveClick}
          disabled={allChangesSaved}
        >
          {saveButtonText}
        </Button>
      </Box>
    );
  }
}

export default withStyles(styles)(EntrySelectHeader);
