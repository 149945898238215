import React, { Component } from "react";

import {
  Typography,
  Link,
} from "@material-ui/core";

import { Link as RouterLink, } from "react-router-dom";

import routes, {
  USER_ENTRIES,
} from "../../data/routes";

class DisplayNameCell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      displayName,
      baseUrl,
      userEntryId,
    } = this.props;

    return (
      <Typography
        variant="body2"
      >
        <Link
          component={RouterLink}
          to={baseUrl + routes[USER_ENTRIES].pathWithoutParam + userEntryId}
        >
          {displayName}
        </Link>
      </Typography>
    )
  }
}

export default (DisplayNameCell);
