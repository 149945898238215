import React, { Component } from "react";

import {
  Box,
  Paper,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";

import { makeStyles, } from "@material-ui/core/styles";

import appearance from "../../services/appearance";

import { get } from "lodash";

import Game from "../Game";

import BracketColumn from "../BracketColumn";

const styles = (theme) => ({
});

const loserFontColor = "#999";

const useStyles = makeStyles((theme) => ({
  teamSeed: {
    marginLeft: theme.spacing(1),
    marginTop: "1px",
    fontSize: "11px",
    width: "11px",
    color: loserFontColor,
  },
  teamName: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
  },
  teamNameWon: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
  },
  teamNameCorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "green",
  },
  teamNameIncorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "red",
  },
  teamNameLoser: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    color: loserFontColor,
  },
  winnerScore: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  loserScore: {
    color: appearance.colors.gray.import[600],
    fontSize: "12px",
  },
  pickRow: {
    backgroundColor: appearance.colors.gray.import[100],
  },
  pickRowCorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid green",
  },
  pickRowIncorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid red",
  },
  finalFourGamePanel: {
    padding: theme.spacing(2),
    backgroundColor: appearance.colors.gray.import[200],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  finalFourGameWrapper: {
    width: "100%",
  },
  championshipGamePanel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  championshipTitle: {
    fontWeight: 500,
  },
  bracketLineGamesComingInLeft: {
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    position: "relative",
    "&::before": {
      position: "absolute",
      left: 0,
      zIndex: 1,
      content: '""',
      display: "block",
      width: "1em",
      height: "5%",
      borderLeft: "2px solid #bdbdbd",
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translate(-2px, 1px)",
      borderBottomLeftRadius: "0.5em"
    },
    "&::after": {
      position: "absolute",
      left: 0,
      zIndex: 1,
      content: '""',
      display: "block",
      width: "1em",
      height: "5%",
      borderLeft: "2px solid #bdbdbd",
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translate(-2px, -1px)",
      borderTopLeftRadius: "0.5em"
    },
  },
}));

const FinalFourPanel = (props) => {
  const classes = useStyles();
  const {
    season,
    userEntry,
  } = props;

  const {
    games,
    teams,
    rounds,
  } = season;

  const {
    picksByGame,
  } = userEntry;

  const final4GameIds = rounds[4];
  const {
    left,
    right,
  } = final4GameIds;

  const leftGameId = left[0];
  const rightGameId = right[0];

  let championshipGameId = get(rounds, [2, "all"]);
  championshipGameId = championshipGameId[0];

  const leftGame = games[leftGameId];
  const rightGame = games[rightGameId];
  const championshipGame = games[championshipGameId];

  const {
    team1Id: leftTeam1IdActual,
    team2Id: leftTeam2IdActual,
  } = leftGame;

  const leftPick = picksByGame[leftGameId];

  const {
    team1Id: leftTeam1IdPicked,
    team2Id: leftTeam2IdPicked,
  } = leftPick;

  const leftTeam1 = teams[leftTeam1IdActual];
  const leftTeam2 = teams[leftTeam2IdActual];
  const leftTeam1Picked = teams[leftTeam1IdPicked];
  const leftTeam2Picked = teams[leftTeam2IdPicked];

  const {
    team1Id: rightTeam1IdActual,
    team2Id: rightTeam2IdActual,
  } = rightGame;

  const rightPick = picksByGame[rightGameId];

  const {
    team1Id: rightTeam1IdPicked,
    team2Id: rightTeam2IdPicked,
  } = rightPick;

  const rightTeam1 = teams[rightTeam1IdActual];
  const rightTeam2 = teams[rightTeam2IdActual];
  const rightTeam1Picked = teams[rightTeam1IdPicked];
  const rightTeam2Picked = teams[rightTeam2IdPicked];

  const {
    team1Id: championshipTeam1IdActual,
    team2Id: championshipTeam2IdActual,
  } = championshipGame;

  const championshipPick = picksByGame[championshipGameId];

  const {
    team1Id: championshipTeam1IdPicked,
    team2Id: championshipTeam2IdPicked,
    winner: championshipWinner,
  } = championshipPick;

  const championshipTeam1 = teams[championshipTeam1IdActual];
  const championshipTeam2 = teams[championshipTeam2IdActual];
  const championshipTeam1Picked = teams[championshipTeam1IdPicked];
  const championshipTeam2Picked = teams[championshipTeam2IdPicked];
  const championshipWinnerPicked = teams[championshipWinner];

  const {
    icon_path,
    name: championshipPickName,
    isEliminated: championshipPickEliminated,
  } = championshipWinnerPicked;

  const final4Games = get(rounds, [4, "all"]);

  return (
    <>
      <Hidden smDown>
        <Grid
          container
          item
          xs={2}
          alignItems="center"
        >
          <Box className={classes.finalFourGamePanel}>
            <Typography variant="h6" gutterBottom>
              Final Four
            </Typography>
            <Box className={classes.finalFourGameWrapper}>
              <Game
                game={leftGame}
                pick={leftPick}
                team1={leftTeam1}
                team2={leftTeam2}
                team1Picked={leftTeam1Picked}
                team2Picked={leftTeam2Picked}
                team1PickedId={leftTeam1IdPicked}
                team2PickedId={leftTeam2IdPicked}
                isFirstRound={false}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Paper
            className={classes.championshipGamePanel}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              className={classes.championshipTitle}
            >
              National Champion
            </Typography>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid item>
                {icon_path && (
                  <img
                    src={require(`../../assets/icons/${icon_path}`).default}
                    height={64}
                    width={64}
                  />
                )}
              </Grid>
              <Grid item>
                {championshipPickEliminated && (
                  <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                  >
                      <s>{championshipPickName}</s>
                  </Typography>
                )}
                {!championshipPickEliminated && (
                  <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                  >
                      {championshipPickName}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                justify="center"
              >
                <Grid item xs={6}>
                  <Game
                    game={championshipGame}
                    pick={championshipPick}
                    team1={championshipTeam1}
                    team2={championshipTeam2}
                    team1Picked={championshipTeam1Picked}
                    team2Picked={championshipTeam2Picked}
                    team1PickedId={championshipTeam1IdPicked}
                    team2PickedId={championshipTeam2IdPicked}
                    isFirstRound={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          item
          xs={2}
          alignItems="center"
        >
          <Box className={classes.finalFourGamePanel}>
            <Typography variant="h6" gutterBottom>
              Final Four
            </Typography>
            <Box className={classes.finalFourGameWrapper}>
              <Game
                game={rightGame}
                pick={rightPick}
                team1={rightTeam1}
                team2={rightTeam2}
                team1Picked={rightTeam1Picked}
                team2Picked={rightTeam2Picked}
                team1PickedId={rightTeam1IdPicked}
                team2PickedId={rightTeam2IdPicked}
                isFirstRound={false}
              />
            </Box>
          </Box>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <BracketColumn 
            gamesInColumn={final4Games} 
            games={games}
            teams={teams}
            picksByGame={picksByGame}
            round={4}
            alignment="left"
            verticalAlignment="top"
            region={"all"}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={classes.bracketLineGamesComingInLeft}
        >
          <Paper
            className={classes.championshipGamePanel}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              className={classes.championshipTitle}
            >
              National Champion
            </Typography>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid item>
                {icon_path && (
                  <img
                    src={require(`../../assets/icons/${icon_path}`).default}
                    height={64}
                    width={64}
                  />
                )}
              </Grid>
              <Grid item>
                {championshipPickEliminated && (
                  <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                  >
                      <s>{championshipPickName}</s>
                  </Typography>
                )}
                {!championshipPickEliminated && (
                  <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                  >
                      {championshipPickName}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                justify="center"
              >
                <Grid item xs={10}>
                  <Game
                    game={championshipGame}
                    pick={championshipPick}
                    team1={championshipTeam1}
                    team2={championshipTeam2}
                    team1Picked={championshipTeam1Picked}
                    team2Picked={championshipTeam2Picked}
                    team1PickedId={championshipTeam1IdPicked}
                    team2PickedId={championshipTeam2IdPicked}
                    isFirstRound={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Hidden>
    </>
  );
};

export default FinalFourPanel;
