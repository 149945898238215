import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, matchPath, } from "react-router-dom";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  withWidth,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import {
  changeCurrentSeason,
  selectCurrentSeason,
  toggleSidebar,
} from './navigationSlice';

const styles = (theme) => ({
});

const allSeasonIds = ["2022", "2023", "2024"];

class SeasonSelector extends Component {
  selectSeason = (open) => (event) => {
    if (event.target.value) {
      this.props.dispatch(changeCurrentSeason(event.target.value))
      this.props.history.push(`/seasons/${event.target.value}`);

      // const isSmallScreen = /xs|sm/.test(this.props.width);
      // if (isSmallScreen) {
      //   this.props.dispatch(toggleSidebar());
      // }
      this.props.dispatch(toggleSidebar());
    }
  };

  changeCurrentSeasonToSeasonInUrl = (url) => {
    const currentSeason = allSeasonIds.filter((id) => url.includes(id))[0];
    if (this.props.currentSeason !== currentSeason) {
      this.props.dispatch(changeCurrentSeason(currentSeason))
    }
  }

  componentDidMount() {
    this.changeCurrentSeasonToSeasonInUrl(this.props.location.pathname);
  };

  render() {
    const {
      currentSeason,
    } = this.props;

    return (
      <FormControl
        fullWidth
        variant="filled"
      >
        <InputLabel id="season-select-label">Current Season</InputLabel>
        <Select
          labelId="season-select-label"
          id="season-select"
          value={currentSeason}
          label="Current Season"
          onChange={this.selectSeason()}
        >
          {allSeasonIds.map((seasonId) => {
            return (
              <MenuItem
                key={seasonId}
                value={seasonId}
              >
                {seasonId}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSeason: selectCurrentSeason(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withRouter,
  withWidth(),
)(SeasonSelector);
