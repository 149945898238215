import {
  BINARY_STRING,
  BINARY_NUMBER,
  ONE_OF,
  CLOSEST,
  WITHIN_AMOUNT,
  WITHIN_PERCENT,
  NUMERIC,
  STRING,
  RADIO,
} from "./question-types";

const katieSeasonPredictionsQuestions = {
  byId: {
    1: {
      id: 1,
      text: "Who is, on average, the worst-ranked contestant from everyone who fills out a bracket?",
      points: 15,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Hunter",
    },
    2: {
      id: 2,
      text: "How many unique contestants cry?",
      points: 20,
      questionType: NUMERIC,
      answerType: BINARY_NUMBER,
      answer: 9,
    },
    3: {
      id: 3,
      text: "Name a contestant who makes Katie cry.",
      points: 20,
      questionType: STRING,
      answerType: ONE_OF,
      possibleAnswers: [
        "mike",
        "greg",
        "cody",
        "aaron",
        "karl",
        "hunter",
        "connor b",
        "michael",
        "thomas",
        "andrew s",
        "justin",
      ],
      possibleAnswersDisplayLabel: [
        "Mike",
        "Greg",
        "Cody",
        "Aaron",
        "Karl",
        "Hunter",
        "Connor B",
        "Michael",
        "Thomas",
        "Andrew S",
        "Justin",
      ],
    },
    4: {
      id: 4,
      text: "Does Tayshia have a heart-to-heart with Katie about what Blake Moynes is like to date?",
      points: 15,
      answerType: BINARY_STRING,
      answer: "No",
    },
    5: {
      id: 5,
      text: "What is the highest # of instagram followers from any non-bachelor nation guest star?",
      points: 25,
      additionalDescription: "(closest guess will get 25pts, 2nd-4th place will get 15pts, and 5th-10th places will get 7.5pts)",
      questionType: NUMERIC,
      answerType: CLOSEST,
      answer: 1507000,
      closestScoring: {
        1: 25,
        2: 15,
        3: 15,
        4: 15,
        5: 7.5,
        6: 7.5,
        7: 7.5,
        8: 7.5,
        9: 7.5,
        10: 7.5,
      },
    },
    6: {
      id: 6,
      text: "What will the average age of the top 4 contestants be?",
      points: 25,
      additionalDescription: "(decimal places encouraged; closest guess will get 25pts, 2nd-4th place will get 15pts, and 5th-10th places will get 7.5pts)",
      questionType: NUMERIC,
      answerType: CLOSEST,
      answer: 29.75,
      closestScoring: {
        1: 25,
        2: 15,
        3: 15,
        4: 15,
        5: 7.5,
        6: 7.5,
        7: 7.5,
        8: 7.5,
        9: 7.5,
        10: 7.5,
      },
    },
    7: {
      id: 7,
      text: "Does Katie spend the night with anyone before fantasy suites?",
      points: 20,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "No",
    },
    8: {
      id: 8,
      text: "Who is the first person to tell Katie that he loves her?",
      points: 25,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Greg",
    },
    9: {
      id: 9,
      text: "How many men does Katie say “I love you” to?",
      points: 25,
      questionType: STRING,
      answerType: BINARY_NUMBER,
      answer: 1,
    },
    10: {
      id: 10,
      text: "Is Katie engaged at the end of “regular season”?",
      points: 30,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Yes",
    },
    11: {
      id: 11,
      text: "Is Katie engaged as of the day after the finale?",
      points: 30,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Yes",
    },
  },
  allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,],
};

const michelleSeasonPredictionsQuestions = {
  byId: {
    1: {
      id: 1,
      text: "Who is, on average, the worst-ranked contestant from everyone who fills out a bracket?",
      points: 15,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "LT",
    },
    2: {
      id: 2,
      text: "What is the highest # of instagram followers from any non-bachelor nation guest star?",
      points: 25,
      additionalDescription: "(closest guess will get 25pts, 2nd-4th place will get 15pts, and 5th-10th places will get 7.5pts)",
      questionType: NUMERIC,
      answerType: CLOSEST,
      answer: 10058503,
      closestScoring: {
        1: 25,
        2: 15,
        3: 15,
        4: 15,
        5: 7.5,
        6: 7.5,
        7: 7.5,
        8: 7.5,
        9: 7.5,
        10: 7.5,
      },
    },
    3: {
      id: 3,
      text: "What will the average age of the top 4 contestants be?",
      points: 25,
      additionalDescription: "(decimal places encouraged; closest guess will get 25pts, 2nd-4th place will get 15pts, and 5th-10th places will get 7.5pts)",
      questionType: NUMERIC,
      answerType: CLOSEST,
      answer: 27.5,
      closestScoring: {
        1: 25,
        2: 15,
        3: 15,
        4: 15,
        5: 7.5,
        6: 7.5,
        7: 7.5,
        8: 7.5,
        9: 7.5,
        10: 7.5,
      },
    },
    4: {
      id: 4,
      text: "Name someone who goes home not on a 1/1 date or at a rose ceremony",
      points: 20,
      questionType: STRING,
      answerType: ONE_OF,
      possibleAnswers: [
        "chris s",
        "jamie",
      ],
      possibleAnswersDisplayLabel: [
        "Chris S",
        "Jamie",
      ],
    },
    5: {
      id: 5,
      text: "As seen in the traier, whose jacket ends up in the pool?",
      points: 20,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Will",
    },
    6: {
      id: 6,
      text: "How many missed basketball shots do we see the men take?",
      points: 20,
      additionalDescription: "(within 3 shots gets points)",
      questionType: NUMERIC,
      answerType: WITHIN_AMOUNT,
      answer: 11,
      threshold: 3,
    },
    7: {
      id: 7,
      text: "Of the basketball shots we see the men take, what % of them go in?",
      points: 10,
      additionalDescription: "(within 15% gets points)",
      questionType: NUMERIC,
      answerType: WITHIN_PERCENT,
      answer: 56,
      threshold: 15,
    },
    8: {
      id: 8,
      text: "Who will be the first person to say any variant of “I love you” / “I could see myself falling in love with you” / “I’m starting to fall in love with you” / a similar phrase to Michelle?",
      points: 25,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Rick",
    },
    9: {
      id: 9,
      text: "How many men will Michelle kiss (in episodes 2 and onwards)?",
      points: 25,
      questionType: NUMERIC,
      answerType: BINARY_NUMBER,
      answer: 10,
    },
    10: {
      id: 10,
      text: "Is Michelle engaged at the end of “regular season”?",
      points: 30,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Yes"
    },
    11: {
      id: 11,
      text: "Is Michelle engaged as of the day after the finale?",
      points: 30,
      questionType: STRING,
      answerType: BINARY_STRING,
      answer: "Yes",
    },
  },
  allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,],
};

const claytonSeasonPredictionsQuestions = {
  byId: {
    1: {
      id: 1,
      text: "How many female contestants cry obviously (very visible tears / aggressive eye-wiping / etc.)?",
      points: 25,
      additionalDescription: "(25 points if exactly correct, 10 points if off by 1)",
      questionType: NUMERIC,
    },
    2: {
      id: 2,
      text: "How many women does Clayton send home off-schedule? ",
      points: 20,
      additionalDescription: "(Not on a 1/1 date or at a rose ceremony)",
      questionType: NUMERIC,
    },
    3: {
      id: 3,
      text: "Do any women talk to Clayton who didn’t receive a rose at the previous ceremony? (either a new contestant or the return of an eliminated contestant)?",
      points: 15,
      questionType: RADIO,
      radioOptions: [
        "Yes",
        "No",
      ],
    },
    4: {
      id: 4,
      text: "Is there a straight-up 2-on-1 date? (i.e. not a different date that turns into a 2-on-1 date)",
      points: 15,
      questionType: RADIO,
      radioOptions: [
        "Yes",
        "No",
      ],
    },
    5: {
      id: 5,
      text: "Does Clayton propose to someone at the end of regular season?",
      points: 30,
      questionType: RADIO,
      radioOptions: [
        "Yes",
        "No",
      ],
    },
    6: {
      id: 6,
      text: "Is Clayton still engaged to that person as of the day of the finale?",
      points: 30,
      questionType: RADIO,
      radioOptions: [
        "Yes",
        "No",
      ],
    },
    7: {
      id: 7,
      text: "When Clayton yells “I’m falling in love and it feels so good!”, is he yelling that at a woman? Or just to the producers/sky? (10 points if woman and right, 5 points if producers/sky and right)",
      points: 10,
      questionType: RADIO,
      radioOptions: [
        "Woman",
        "Producers/sky",
      ],
    },
    8: {
      id: 8,
      text: "How many women does Clayton kiss starting with episode 2?",
      points: 25,
      questionType: NUMERIC,
    },
    9: {
      id: 9,
      text: "How many women does Clayton kiss while picking them up?",
      points: 20,
      questionType: NUMERIC,
    },
    10: {
      id: 10,
      text: "How many countries will the contestants visit?",
      points: 15,
      questionType: NUMERIC,
    },
    11: {
      id: 11,
      text: "How many times do we see a contestant wear a bathing suit?",
      points: 30,
      additionalDescription: "(i.e. one woman can be counted multiple times, one group date would also count as multiple) (30 points to closest person, 15 points to 2nd-3rd closest, 10 points to 4th-8th closest)",
      questionType: NUMERIC,
    },
    12: {
      id: 12,
      text: "How many competition group dates will there be?",
      points: 25,
      additionalDescription: "(25 points if exactly correct, 10 points if off by 1)",
      questionType: NUMERIC,
    },
  },
  allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,],
};

export const seasonPredictionsQuestionsBySeason = {
  "katie_2021": katieSeasonPredictionsQuestions,
  "michelle_2021": michelleSeasonPredictionsQuestions,
  "clayton_2022": claytonSeasonPredictionsQuestions,
};
