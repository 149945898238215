import firebase, { auth, firestore, } from "../firebase";

import { isLoaded, } from "react-redux-firebase";

import { get } from "lodash";

import {
  currentSeason,
  setupSteps,
  rankings,
  seasonPredictions,
} from "../data/season-constants";

import {
  seasonPredictionsQuestionsBySeason
} from "../data/season-predictions-data";


const userEntries = {};

userEntries.updateRankings = (userEntry, rankings) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          rankings: rankings,
        },
        { merge: true }
      )
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          rankings: rankings,
          userId: uid,
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.updateSeasonPredictions = (userEntry, seasonPredictions) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    let filteredSeasonPredictions = {}, questionId;
    for (questionId in seasonPredictions) {
      if (seasonPredictions[questionId] !== "") {
        filteredSeasonPredictions[questionId] = seasonPredictions[questionId]; 
      }
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          seasonPredictions: filteredSeasonPredictions,
        },
        { merge: true }
      )
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          seasonPredictions: filteredSeasonPredictions,
          userId: uid,
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.updateFirstFourPicks = (userEntries, firstFourPicks, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    userEntries.forEach(entry => {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(entry.id);
      userEntryRef.set({ 
          firstFourPicks: firstFourPicks,
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar("Saved pick!", "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    });
  });
};

userEntries.updateName = (userEntries, name, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    userEntries.forEach(entry => {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(entry.id);

      if (name === "") {
        userEntryRef.set({ 
            name: firebase.firestore.FieldValue.delete(),
          },
          { merge: true }
        )
        .then(() => {
          openSnackbar(`Saved ${entry.bracketName}!`, "success")
        })
        .catch((reason) => {
          reject(reason);
        });
      } else {
        userEntryRef.set({ 
            name: name,
          },
          { merge: true }
        )
        .then(() => {
          openSnackbar(`Saved ${entry.bracketName}!`, "success")
        })
        .catch((reason) => {
          reject(reason);
        });
         
      }
    });
  });
};

userEntries.updateBracket1Name = (userEntry, name, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          bracket1Name: name,
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar("Saved bracket name!", "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          bracket1Name: name,
          userId: uid,
        })
        .then(() => {
          openSnackbar("Saved bracket name!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.updateBracket2Name = (userEntry, name, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          bracket2Name: name,
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar("Saved second bracket name!", "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          bracket2Name: name,
          userId: uid,
        })
        .then(() => {
          openSnackbar("Saved second bracket name!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.updateClaimedBrackets = (checkedBrackets, uncheckedBrackets, name = undefined, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    const firstFourPicks = checkedBrackets
      .map((entry) => get(entry.data[0], "firstFourPicks"))
      .find((picks) => picks);

    checkedBrackets.forEach(entry => {
      const {
        data,
      } = entry;

      const entryId = data[0].id;
      const bracketName = data[0].bracketName;

      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(entryId);

      userEntryRef.set({ 
          userId: uid,
          ...(name !== undefined && name !== "") && {name: name},
          ...(firstFourPicks) && {firstFourPicks: firstFourPicks}
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar(`Claimed ${bracketName}!`, "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    });

    uncheckedBrackets.forEach(entry => {
      const {
        data,
      } = entry;

      const entryId = data[0].id;
      const bracketName = data[0].bracketName;

      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(entryId);

      userEntryRef.set({ 
          userId: firebase.firestore.FieldValue.delete(),
          name: firebase.firestore.FieldValue.delete(),
          firstFourPicks: firebase.firestore.FieldValue.delete(),
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar(`Unclaimed ${bracketName}!`, "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    });
  });
};

userEntries.clearBracketsUserId = (uncheckedBrackets, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    uncheckedBrackets.forEach(entry => {
      console.log(entry);
      const {
        id,
        bracketName,
      } = entry;

      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(id);

      userEntryRef.set({ 
          userId: firebase.firestore.FieldValue.delete(),
          name: firebase.firestore.FieldValue.delete(),
          firstFourPicks: firebase.firestore.FieldValue.delete(),
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar(`Unclaimed ${bracketName}!`, "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    });
  });
};

userEntries.isStepComplete = (step, userEntry, season) => {
  if (!userEntry || !season) {
    return false;
  }

  switch (step) {
    case rankings:
      return userEntry.rankings !== undefined && userEntry.rankings.length == season.numContestants;
    case seasonPredictions:
      return userEntry.seasonPredictions !== undefined && Object.keys(userEntry.seasonPredictions).length == seasonPredictionsQuestionsBySeason[season.id].allIds.length;
  }
}

userEntries.isRankingComplete = (myUserEntryAll, seasons) => {
  const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);
  const season = seasons === undefined ? undefined : seasons[currentSeason];

  return userEntries.isStepComplete(rankings, myUserEntry, season);
};

userEntries.getIncompleteSetupSteps = (userEntry, season) => {
  if (!userEntry) {
    return setupSteps;
  } else {
    return setupSteps.filter(step => !this.isStepComplete(step, userEntry, season))
  }
};

userEntries.allStepsComplete = (myUserEntryAll, seasons) => {
  const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);
  const season = seasons === undefined ? undefined : seasons[currentSeason];

  if (!myUserEntry || !season) {
    return false;
  } else {
    return userEntries.isStepComplete(rankings, myUserEntry, season)
      && userEntries.isStepComplete(seasonPredictions, myUserEntry, season);
  }
};

userEntries.getMyUserEntryFromAllOrDefault = (myUserEntryAll) => {
  if (!isLoaded(myUserEntryAll) || myUserEntryAll === undefined || myUserEntryAll.length == 0) {
    return {};
  }

  return myUserEntryAll[0];
};

userEntries.getCurrentSeasonOrDefault = (seasons) => {
  if (!isLoaded(seasons) || seasons === undefined) {
    return {};
  }

  return seasons[currentSeason];
};

export default userEntries;