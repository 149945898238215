import React, { Component } from "react";

import {
  Box,
  Typography,
  Hidden,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import Game from "../Game";

const styles = (theme) => ({
});

class FirstFourPicks extends Component {
  render() {
    const {
      games,
      teams,
      handleAnswerChange,
      firstFourPicks,
      inMainBracket,
      disabled,
    } = this.props;

    const firstFourGameIds = ["63", "64", "65", "66"]

    if (inMainBracket) {
      return (
        <Box m={1}>
          <Hidden mdUp>
            <Box>
              <Typography variant="h6" gutterBottom>
                First Four
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
            >
              {firstFourGameIds.map((gameId) => {
                const game = games[gameId];

                const {
                  id,
                  team1Id: team1IdActual,
                  team2Id: team2IdActual,
                } = game;

                const team1 = teams[team1IdActual];
                const team2 = teams[team2IdActual];
                const firstFourPick = firstFourPicks[id];
                const pick = {
                  winner: firstFourPick,
                };

                return (
                  <Box pb={2} key={id}>
                    <Game
                      game={game}
                      pick={pick}
                      team1={team1}
                      team2={team2}
                      team1Picked={team1}
                      team2Picked={team2}
                      team1PickedId={team1IdActual}
                      team2PickedId={team2IdActual}
                      firstFourPick={firstFourPick}
                      isFirstRound
                    />
                  </Box>
                )
              })}
            </Box>

          </Hidden>
          <Hidden smDown>
            <Box>
              <Typography variant="h6" gutterBottom>
                First Four
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-around"
            >
              {firstFourGameIds.map((gameId) => {
                const game = games[gameId];

                const {
                  id,
                  team1Id: team1IdActual,
                  team2Id: team2IdActual,
                } = game;

                const team1 = teams[team1IdActual];
                const team2 = teams[team2IdActual];
                const firstFourPick = firstFourPicks[id];
                const pick = {
                  winner: firstFourPick,
                };

                return (
                  <Box pb={2} key={id}>
                    <Game
                      game={game}
                      pick={pick}
                      team1={team1}
                      team2={team2}
                      team1Picked={team1}
                      team2Picked={team2}
                      team1PickedId={team1IdActual}
                      team2PickedId={team2IdActual}
                      firstFourPick={firstFourPick}
                      isFirstRound
                    />
                  </Box>
                )
              })}
            </Box>
          </Hidden>
        </Box>
      );
    }

    return (
      <Box>
        {firstFourGameIds.map((gameId) => {
          const game = games[gameId];

          const {
            id,
            team1Id: team1IdActual,
            team2Id: team2IdActual,
          } = game;

          const team1 = teams[team1IdActual];
          const team2 = teams[team2IdActual];
          const firstFourPick = firstFourPicks[id];

          return (
            <Box
              pb={2}
              key={gameId}
            >
              <Game
                game={game}
                pick={team1}
                team1={team1}
                team2={team2}
                // team1Picked={leftTeam1Picked}
                // team2Picked={leftTeam2Picked}
                // team1PickedId={leftTeam1IdPicked}
                // team2PickedId={leftTeam2IdPicked}
                isFirstFour={true}
                handleAnswerChange={handleAnswerChange}
                firstFourPick={firstFourPick}
                disabled={disabled}
              />
            </Box>
          )
        })}
      </Box>
    )
  }
}

export default withStyles(styles)(FirstFourPicks);
