import React, { Component } from "react";

import EmptyState from "../EmptyState";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, } from "react-router-dom";

import { get, } from "lodash";

import {
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

import {
  Box,
  Typography,
} from "@material-ui/core";

import AdminGameEntry from "../AdminGameEntry";

import Loader from "../Loader";

import {
  currentSeason,
} from "../../data/season-constants";

class AdminPage extends Component {
  isLoaded = () => {
    const {
      seasons,
    } = this.props;

    return isLoaded(
      seasons,
    );
  }

  render() {
    const {
      seasons,
      openSnackbar,
    } = this.props;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const season = seasons[seasonId];

    const {
      games,
      rounds,
      teams,
    } = season;

    const firstRound = rounds[64];
    const secondRound = rounds[32];
    const sweet16 = rounds[16];
    const elite8 = rounds[8];
    const final4 = rounds[4];
    const championship = rounds[2];
    const firstFourGameIds = ["63", "64", "65", "66"]

    return (
      <Box>
        <Typography
          variant="h6"
          gutterBottom
        >
          First Four
        </Typography>
        {firstFourGameIds.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
        <Typography
          variant="h6"
          gutterBottom
        >
          Round of 64
        </Typography>
        {firstRound.all.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
        <Typography
          variant="h6"
          gutterBottom
        >
          Round of 32
        </Typography>
        {secondRound.all.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
        <Typography
          variant="h6"
          gutterBottom
        >
          Sweet 16
        </Typography>
        {sweet16.all.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
        <Typography
          variant="h6"
          gutterBottom
        >
          Elite 8
        </Typography>
        {elite8.all.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
        <Typography
          variant="h6"
          gutterBottom
        >
          Final 4
        </Typography>
        {final4.all.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
        <Typography
          variant="h6"
          gutterBottom
        >
          Championship
        </Typography>
        {championship.all.map((gameId) => {
          const game = games[gameId];

          const {
            team1Id,
            team2Id,
          } = game;

          const team1 = teams[team1Id];
          const team2 = teams[team2Id];
          
          return (
            <AdminGameEntry 
              key={gameId}
              game={games[gameId]}
              team1={team1}
              team2={team2}
              openSnackbar={openSnackbar}
            />
          )
        })}
      </Box>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    seasons: state.firestore.data.seasons,
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
  ];
}

export default compose(
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(AdminPage);
