import React, { Component } from "react";

import PropTypes from "prop-types";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { firestoreConstants, } from "../../firebase";

import { firestoreConnect } from 'react-redux-firebase'

import { BrowserRouter, Switch, Redirect, Route, useRouteMatch, } from "react-router-dom";

import { Toolbar, Box, Container, } from "@material-ui/core";

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import StandingsPage from "../StandingsPage";
import UserEntryPage from "../UserEntryPage";
import NotFoundPage from "../NotFoundPage";
import FAQPage from "../FAQPage";

import Sidebar from "../../features/navigation/Sidebar";
import ScrollToTop from "../ScrollToTop";

import {
  selectCurrentSeason,
} from '../../features/navigation/navigationSlice';

import {
  defaultSeason,
} from "../../data/season-constants";

import routes, {
  HOME,
  STANDINGS,
  USER_ENTRIES,
  SEASONS,
  ADMIN,
  FAQS,
} from "../../data/routes";

function SeasonRouter(props) {
  // Properties
  const {
    user,
  } = props;

  // Functions
  const {
    currentSeason,
    openSnackbar,
    onSignUpClick,
    onSignInClick,
    onSettingsClick,
  } = props;

  const match = useRouteMatch();
  const baseUrl = match.url.replace(/\/+$/, "");

  return (
    <Switch>
      <Route exact path={`${match.path}/`}>
        {/* {currentSeason === defaultSeason && (
          <HomePage
            user={user}
            openSnackbar={openSnackbar}
            onSignUpClick={onSignUpClick}
            onSignInClick={onSignInClick}
            onSettingsClick={onSettingsClick}
            baseUrl={match.url}
          /> 
        )}
        {currentSeason !== defaultSeason && (
          <StandingsPage
            user={user}
            onSettingsClick={onSettingsClick}
            baseUrl={match.url}
          /> 
        )} */}
        <StandingsPage
          user={user}
          onSettingsClick={onSettingsClick}
          baseUrl={baseUrl}
        /> 
      </Route>

      <Route exact path={`${match.path}${routes[ADMIN].path}`}>
        <AdminPage
          openSnackbar={openSnackbar}
        />
      </Route>

      <Route exact path={`${match.path}${routes[STANDINGS].path}`}>
        <StandingsPage
          user={user}
          onSettingsClick={onSettingsClick}
          baseUrl={baseUrl}
        /> 
      </Route>
      <Route exact path={`${match.path}${routes[USER_ENTRIES].path}`}>
        <UserEntryPage /> 
      </Route>
      <Route exact path={`${match.path}${routes[FAQS].path}`}>
        <FAQPage /> 
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

class Router extends Component {
  render() {
    // Properties
    const {
      user,
      roles,
      bar,
    } = this.props;

    // Functions
    const {
      openSnackbar,
      onSignUpClick,
      onSignInClick,
      onSettingsClick,
     } = this.props;

    const {
      currentSeason,
    } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}
        <Sidebar />
        <Container
          maxWidth="lg"
          disableGutters
        >
          <Box flex={1}>
            <Toolbar />
            <ScrollToTop />
            <Switch>
              <Route exact path={routes[HOME].path}>
                <Redirect to={routes[SEASONS].pathWithoutParam + currentSeason} />
              </Route>

              <Route
                path={routes[SEASONS].path}
              >
                <SeasonRouter
                  user={user}
                  roles={roles}
                  openSnackbar={openSnackbar}
                  onSignUpClick={onSignUpClick}
                  onSignInClick={onSignInClick}
                  onSettingsClick={onSettingsClick}
                  currentSeason={currentSeason}
                />
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
            </Box>
          </Container>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentSeason: selectCurrentSeason(state),
  };
}

export default compose(
  connect(mapStateToProps),
)(Router);
